import React from "react";


import Header from "./components/Header/Header";

import MainPage from "./components/MainPage/MainPage";


export default function App() {
 
  return (<div >
    <Header />
    <MainPage />
  </div>)
}

